














import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'Checkbox',
})
export default class Checkbox extends Vue {
  innerValue: boolean;

  @Prop({ default: false }) value: boolean;
  @Prop({ default: 'middle' }) size: string;

  data() {
    return {
      innerValue: this.value,
    };
  }

  @Emit('change')
  onChange() {
    return this.innerValue;
  }
}

import { Component, Vue } from 'vue-property-decorator';
import { BrowserEvents } from '@/services/helpers/constants';

export const ClickOutsideEvent = 'clickOutside';

@Component
export default class DetectClickOutside extends Vue {
  private detectClickOutside(e: Event): void {
    const path = e.composedPath();

    if (path.indexOf(this.$el as EventTarget) === -1) {
      this.$emit(ClickOutsideEvent, e);
    }
  }

  mounted() {
    setTimeout(() => window.addEventListener(BrowserEvents.click, this.detectClickOutside), 0);
  }

  beforeDestroy() {
    window.removeEventListener(BrowserEvents.click, this.detectClickOutside);
  }
}
